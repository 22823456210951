import React from 'react';
import cx from 'classnames';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import { ModuleWrapper } from '../moduleWrapper';
const PageSpacer = ({ config, size, showMob, showDesk }) => {
  const breakpoints = useBreakpoint();

  const spacerClasses = cx('block', {
    'h-space-s': size === 'small',
    'h-space-m': size === 'medium',
    'h-space-l': size === 'large',
  });

  return (
    <>
      {showMob && !breakpoints.md && (
        <ModuleWrapper {...config}>
          <div className={spacerClasses} />
        </ModuleWrapper>
      )}
      {showDesk && breakpoints.md && (
        <ModuleWrapper {...config}>
          <div className={spacerClasses} />
        </ModuleWrapper>
      )}
    </>
  );
};

export default PageSpacer;
